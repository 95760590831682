import * as ALL_HERO_DATA from './all_hero_data.json'

export type Spell = {
    maxCooldown: number[] | number[][];
    icon: string;
    name: string;
}

export type Item = {
    icon: string;
    name: string;
}

export type Hero = {
    spells: Spell[];
    icon: string;
    name: string;
    cooldownTalentAmount?: undefined;
}
export const AllHeroData: Hero[] = (JSON.parse(JSON.stringify(ALL_HERO_DATA)) as any)['allHeroes']

export const BlackKingBar: Spell = { icon: 'https://cdn.cloudflare.steamstatic.com/apps/dota2/images/dota_react/items/black_king_bar.png', maxCooldown: [90], name: 'black_king_bar' };
export const Refresher: Spell = { icon: 'https://cdn.cloudflare.steamstatic.com/apps/dota2/images/dota_react/items/refresher.png', maxCooldown: [180], name: 'refresher' };
export const OctarineCore: Item = { icon: 'https://cdn.cloudflare.steamstatic.com/apps/dota2/images/dota_react/items/octarine_core.png', name: 'octarine_core' };

export const BuyBack = (hero: Hero): Spell => {
    return { icon: hero.icon, name: 'buyback', maxCooldown: [480] }
}