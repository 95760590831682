import { FC, PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useRef } from "react"
import { MatchData, MatchSpell, MatchToggle, useGlobalData } from "./GlobalDataProvider";
import { useWebSocket } from "./SocketProvider";
import { OnGroupDataMessageArgs } from "@azure/web-pubsub-client";

export type CurrentMatchContext = {
    match: MatchData;
    updateSpellData: (spell: MatchSpell) => void;
    updateSelectedHeroes: (selectedHeroes: string[]) => void;
    updateToggle: (toggle: MatchToggle) => void;
}

export const currentMatchContext = createContext<CurrentMatchContext>({
    match: { data: {}, matchId: -1, selectedHeroes: [], toggle: {} },
    updateSpellData: () => { },
    updateSelectedHeroes: () => { },
    updateToggle: () => { }
});


export const useCurrentMatch = () => useContext(currentMatchContext);

export type CurrentMatchProviderProps = {
    matchId: number;
} & PropsWithChildren;

export type WebSocketData = {
    eventType: string;
    toUserId?: string;
}

export type SpellGroupMessage = {
    matchId: number;
    spell: MatchSpell | undefined;
    selectedHeroes: string[] | undefined;
    toggle: MatchToggle | undefined;
    eventType: 'setState';
} & WebSocketData

export type GetStateMessage = {
    eventType: 'getState';
    matchId: number;
} & WebSocketData

export const CurrentMatchProvider: FC<CurrentMatchProviderProps> = ({ matchId, children }) => {
    const { matches, updateMatchData, getMatch } = useGlobalData();
    const { webSocket, sendMatchData, userId } = useWebSocket();
    const fnRef = useRef<((e: OnGroupDataMessageArgs) => void) | undefined>();
    const connectedFnRef = useRef<(() => void) | undefined>();
    useEffect(() => {
        if (webSocket && matchId && matchId > 0) {
            const fn = (e: OnGroupDataMessageArgs) => {
                console.log(`group-message for ${matchId}`, e)
                const data = e.message.data as SpellGroupMessage | GetStateMessage;
                if (data.eventType === 'setState') {
                    if (e.message.fromUserId !== userId && (!data.toUserId || data.toUserId === userId)) {
                        updateMatchData(data.matchId, data.spell, data.selectedHeroes, data.toggle);
                    }
                }
                else if (data.eventType === 'getState') {
                    if (e.message.fromUserId) {
                        const match = getMatch(matchId);
                        if (match) {
                            webSocket.sendToGroup(matchId.toString(), {
                                matchId: matchId,
                                selectedHeroes: match.selectedHeroes,
                                spell: match.data,
                                toggle: match.toggle,
                                eventType: 'setState',
                                toUserId: e.message.fromUserId
                            }, 'json');
                        }
                    }
                }
            };
            fnRef.current = fn;
            webSocket.on('group-message', fn);

            const connectedFn = () => {
                webSocket.sendToGroup(matchId.toString(), { eventType: 'getState', matchId: matchId } as GetStateMessage, 'json')
            }
            connectedFnRef.current = connectedFn
            webSocket.on('connected', connectedFn);
            webSocket.joinGroup(matchId.toString())
        }
        return () => {
            if (webSocket && matchId && matchId > 0) {
                if (fnRef.current) {
                    webSocket.off('group-message', fnRef.current);
                    webSocket.off('connected' as any, connectedFnRef.current!);
                    fnRef.current = undefined;
                    connectedFnRef.current = undefined;
                }
                webSocket.leaveGroup(matchId.toString());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [webSocket, matchId]);

    const updateSpellData = useCallback((spell: MatchSpell) => {
        updateMatchData(matchId, spell, undefined, undefined);
        sendMatchData(matchId, { spell });
    }, [matchId, updateMatchData, sendMatchData]);


    const updateSelectedHeroes = useCallback((selectedHeroes: string[]) => {
        updateMatchData(matchId, undefined, selectedHeroes, undefined);
        sendMatchData(matchId, { selectedHeroes });
    }, [matchId, updateMatchData, sendMatchData]);

    const updateToggle = useCallback((toggle: MatchToggle) => {
        updateMatchData(matchId, undefined, undefined, toggle);
        sendMatchData(matchId, { toggle: toggle });
    }, [matchId, updateMatchData, sendMatchData]);

    const value: CurrentMatchContext = useMemo(() => {
        const currentMatch = matches[matchId] ?? { data: {}, matchId };
        return { match: currentMatch, updateSpellData, updateSelectedHeroes, updateToggle };
    }, [matches, updateSpellData, updateSelectedHeroes, updateToggle, matchId])
    return <currentMatchContext.Provider value={value}>{children}</currentMatchContext.Provider>
}