import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha, InputBase, InputBaseProps, } from '@mui/material';
import { FC } from 'react';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        '-moz-appearance': 'textfield',
        '-webkit-appearance': 'none',
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

export type SearchInputProps = {
    onSearch: (text: string) => void;
    mode: 'ON_SUBMIT' | 'AS_TYPE'
} & InputBaseProps;
export const SearchInput: FC<SearchInputProps> = ({ onSearch: handleSearch, mode, ...rest }) => {
    return (<Search>
        <SearchIconWrapper>
            <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase

            onKeyUp={(e) => {
                if (mode === 'ON_SUBMIT') {
                    if (e.key === 'Enter') {
                        const search = (e.target as HTMLInputElement)?.value
                        handleSearch(search ?? '')
                    }
                }
                else {
                    const search = (e.target as HTMLInputElement)?.value
                    handleSearch(search ?? '')
                }

            }}
            {...rest}
        />
    </Search>);
}