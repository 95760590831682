import { FC } from "react";
import { CooldownButton, CooldownButtonProps } from "./CooldownButton"

export type AegisButtonProps = {

} & Omit<CooldownButtonProps, 'width' | 'height' | 'spell' | 'hero'>;

export const AegisButton: FC<AegisButtonProps> = ({ ...rest }) => {
    return (<CooldownButton
        hero={{ icon: '', name: 'roshan', spells: [] }}
        spell={{
            icon: './aegis.png',
            name: 'roshan',
            maxCooldown: [300]
        }}
        width={128} height={128} {...rest} />);
}