import { FC } from "react"
import { BlackKingBar, BuyBack, Hero, OctarineCore, Refresher } from "../../data/allHeroData"
import { Box, Stack } from "@mui/material"
import { HeroButton } from "../CooldownButton/HeroButton"
import { SpellButton } from "../CooldownButton/SpellButton"
import { ItemButton } from "../CooldownButton/ItemButton"
import { ItemToggleButton } from "../ToggleButton/ItemToggleButton"

export type HeroSpellBoardProps = {
    hero: Hero;
    onlyShowLong?: boolean;
}
export const HeroSpellBoard: FC<HeroSpellBoardProps> = ({ hero, onlyShowLong }) => {
    return <Box sx={{position: 'relative'}}>
        <Stack direction="column" spacing="2px">
            <Stack direction="row" spacing="2px">
                <Stack direction="column" spacing="2px">
                    <ItemButton hero={hero} spell={BlackKingBar} />
                    <ItemButton hero={hero} spell={Refresher} />
                </Stack>
                <HeroButton hero={hero} spell={BuyBack(hero)} />
                <ItemToggleButton hero={hero} item={OctarineCore} sx={{position: 'absolute', right: '0px'}} />
            </Stack>
            <Stack direction="row" spacing="2px">
                {hero.spells.filter(s => Math.max(...s.maxCooldown?.flatMap(cd => cd)) > (onlyShowLong ? 40 : 0)).map(spell => <SpellButton key={`${hero.name}_${spell.name}`} hero={hero} spell={spell} />)}
            </Stack>
        </Stack>
    </Box>
}