import { Box, Button, Divider } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { SearchInput } from "../SearchInput";
import { HeroPickerHero } from "./HeroPickerHero";
import { AllHeroData } from "../../data/allHeroData";


export type HeroPickerHeroGridProps = {
    onSelect: (name: string) => void;
    selectedHeroes: string[];
    onClear: () => void;
}

const HEROES = AllHeroData.map(d => ({ icon: d.icon, name: d.name }));

export const HeroPickerHeroGrid: FC<HeroPickerHeroGridProps> = ({ onSelect: handleSelect, selectedHeroes, onClear: handleClear }) => {
    const [search, setSearch] = useState<string | undefined>();
    useEffect(() => {

    }, [search])

    return (<Box>
        <Box>
            <SearchInput autoFocus sx={{ width: '100%', '& input': { width: '100% !important' } }} placeholder="Search..." onSearch={(text) => { setSearch(text) }} mode='AS_TYPE' />
        </Box>
        <Divider />
        <Box sx={{ maxHeight: '400px', overflowY: 'auto', paddingTop: '4px', px: '2px' }}>
            {HEROES.filter(h => !search || h.name.toLowerCase().includes(search)).map(h => <HeroPickerHero key={h.name} id={h.name} name={h.name} imageUrl={h.icon} isSelected={selectedHeroes.includes(h.name)} onSelect={handleSelect} />)}
        </Box>
        <Box>
            <Button onClick={handleClear}>Clear</Button>
        </Box>
    </Box>);
}