import { Box } from "@mui/material";
import { FC } from "react"

export type HeroPickerHeroProps = {
    name: string;
    id: string;
    imageUrl: string;
    isSelected: boolean;
    onSelect: (name: string) => void;
}

export const HeroPickerHero: FC<HeroPickerHeroProps> = ({ imageUrl, id, isSelected, onSelect: handleSelect, name }) => {
    return <Box onClick={() => handleSelect(id)} sx={{
        border: isSelected ? '5px solid gold' : '5px solid black',
        '&:hover': {
            border: isSelected ? '5px solid rgb(182, 155, 0)' : '5px solid rgb(97, 82, 0)',
        },
        display: "inline-block",
        lineHeight: 0,
        width: '75px',
        height: '47px',
        marginTop: '-2px',
        '& img': {
            filter: isSelected ? 'grayscale(0)' : 'grayscale(.75)' ,
            '&:hover': {
                filter: isSelected ? 'grayscale(.1)' : 'grayscale(.25)',
            }
        },
        
    }}>
        <img src={imageUrl} alt={name} width={'100%'} height={'100%'} />
    </Box>
}