import { FC } from "react";
import { CooldownButton, CooldownButtonProps } from "./CooldownButton"

export type TormentorButtonProps = {
    enemy?: boolean;
} & Omit<CooldownButtonProps, 'width' | 'height' | 'spell' | 'hero'>;

export const TormentorButton: FC<TormentorButtonProps> = ({ enemy, ...rest }) => {
    return (<CooldownButton
        hero={{ icon: '', name: enemy ? 'enemy' : 'ally', spells: [] }}
        spell={{
            icon: enemy ? './tormentor_enemy.png' : './tormentor_ally.png',
            name: 'tormentor',
            maxCooldown: [600]
        }}
        width={128} height={128} {...rest} />);
}