import { FC, useCallback, useEffect, useRef, useState } from "react"
import { Hero, OctarineCore, Spell } from "../../data/allHeroData"
import { Box, Button, SxProps } from "@mui/material";
import { useCurrentMatch } from "../../Providers/CurrentMatchProvider";
import { useAnimationFrame } from "../../Hooks/UseAnimationFrame";
export type CooldownButtonProps = {
    spell: Spell;
    hero: Hero;
    height: number;
    width: number;
    showLevels?: boolean;
    sx?: SxProps;
    cooldownTextSx?: SxProps;
    fontSize?: number;
    clickable?: boolean;
    effectedByOctarine?: boolean;
}

const adjCooldown = (cd: number | number[], isOctarineActive: boolean): number | number[] => {
    if (isOctarineActive) {
        if (Array.isArray(cd)) {
            return cd.map(c => Math.round(c * (1 - .25)));
        }
        else {
            // cd * (1-cdr)*(1-cdr)
            return Math.round(cd * (1 - .25));
        }
    }
    return cd;
}

export const CooldownButton: FC<CooldownButtonProps> = ({ hero, spell, height, width, showLevels, sx, cooldownTextSx, fontSize, clickable, effectedByOctarine }) => {
    const [currentCooldown, setCurrentCooldown] = useState<number[]>([0]);

    const id = `${hero.name}_${spell.name}`;
    const { match, updateSpellData } = useCurrentMatch();
    const spellData = match.data[id];
    const level = spellData?.level ?? 0;
    const cooldownRef = useRef<number[]>([0]);
    const activatedTimestampRef = useRef<number>(0);
    const levelRef = useRef<number>(spellData?.level ?? 0);
    const isOctarineActive = !!effectedByOctarine && !!match.toggle[`${hero.name}_${OctarineCore.name}`]?.active;
    const isOctarineActiveRef = useRef<boolean>(isOctarineActive);

    useEffect(() => {
        isOctarineActiveRef.current = isOctarineActive;
    }, [isOctarineActive]);

    useEffect(() => {
        let maxCooldown = spell.maxCooldown[spellData?.level ?? 0];
        if (isOctarineActive) {
            if (Array.isArray(maxCooldown)) {
                maxCooldown = maxCooldown.map(c => c * (1 - .25));
            }
            else {
                // cd * (1-cdr)*(1-cdr)
                maxCooldown = maxCooldown * (1 - .25);
            }
        }
        const cooldown = Array.isArray(maxCooldown) ? [maxCooldown[0] * 1000, maxCooldown[1] * 1000] :
            [maxCooldown * 1000];
        activatedTimestampRef.current = spellData?.activatedTimestamp ?? 0;
        const remainingCooldownMs = cooldown.map((cd) => spellData?.activatedTimestamp ? cd - (new Date().getTime() - spellData.activatedTimestamp) : 0);
        cooldownRef.current = remainingCooldownMs;
        levelRef.current = spellData?.level ?? 0;
        setCurrentCooldown(remainingCooldownMs.map(cd => Math.round(cd / 1000)));
    }, [spellData, spell.maxCooldown, isOctarineActive]);

    useAnimationFrame((deltaTime: number) => {
        if (Math.max(...cooldownRef.current) > 0) {
            let maxCooldown = spell.maxCooldown[levelRef.current ?? 0];
            if (isOctarineActiveRef.current) {
                if (Array.isArray(maxCooldown)) {
                    maxCooldown = maxCooldown.map(c => c * (1 - .25));
                }
                else {
                    // cd * (1-cdr)*(1-cdr)
                    maxCooldown = maxCooldown * (1 - .25);
                }
            }
            const cooldown = Array.isArray(maxCooldown) ? [maxCooldown[0] * 1000, maxCooldown[1] * 1000] :
                [maxCooldown * 1000];

            const secondsBefore = cooldownRef.current.map(cd => Math.round(cd / 1000));
            const remainingCooldownMs = cooldown.map((cd) => activatedTimestampRef.current ? cd - (new Date().getTime() - activatedTimestampRef.current) : 0);
            cooldownRef.current = remainingCooldownMs;
            const secondsAfter = cooldownRef.current.map(cd => Math.round(cd / 1000) < 0 ? 0 : Math.round(cd / 1000));

            const hasChange = secondsAfter.map((after, i) => after !== secondsBefore[i]).reduce((a, b) => a || b, false);
            if (hasChange) {
                setCurrentCooldown(secondsAfter);
            }
        }
    });

    const handleClick = useCallback(() => {
        // cooldownRef.current = spellCooldown;
        // setCurrentCooldown(Math.round(spellCooldown / 1000));
        updateSpellData({ id: id, activatedTimestamp: new Date().getTime(), level: level });

    }, [id, updateSpellData, level])

    const handleResetClick = useCallback(() => {
        updateSpellData({ id: id, activatedTimestamp: 0, level: level });

    }, [id, updateSpellData, level])

    const handleChangeLevel = useCallback((newLevel: number) => {
        if (level !== newLevel) {
            updateSpellData({ id: id, activatedTimestamp: spellData?.activatedTimestamp, level: newLevel });
        }
    }, [id, updateSpellData, spellData, level])

    fontSize = fontSize ?? 56;
    let adjFontSize = fontSize;

    const timerTexts = currentCooldown.map((cd, i) => (cd > 60 ? `${Math.floor(cd / 60)}:${(cd % 60).toString().padStart(2, '0')}` : cd > 0 ? cd.toString() : ''));
    if (Math.max(...timerTexts.map(t => t.length)) > 4 && adjFontSize > 48) {
        adjFontSize = 48;
    }

    return (<Box sx={{ border: '2px solid black', width: `${width}px`, boxSizing: 'content-box', height: `${showLevels ? height + 24 : height}px`, ...sx }}>
        <Box>
            <Box sx={{
                height: `${height}px`, width: `${width}px`, position: 'relative',
                '&:hover .clear-button': {
                    display: 'block'
                }
            }}>
                <Button sx={{ padding: 0 }} onClick={(clickable === undefined || clickable === true) ? handleClick : undefined}>
                    <img src={spell.icon} alt={spell.name} height={height} width={width} />
                    {Math.max(...currentCooldown) > 0 && (<>
                        <Box sx={{
                            backgroundColor: 'gray',
                            opacity: .5,
                            position: 'absolute',
                            top: '0px',
                            left: '0px',
                            height: '100%',
                            width: '100%'
                        }} />
                        {currentCooldown.map((cd, i) => (<Box key={i.toString()} sx={{
                            position: 'absolute',
                            top: `calc(${((i + 1) * (100 / (currentCooldown.length + 1)))}% - ${(fontSize!) / 2}px)`,
                            lineHeight: 1,
                            left: '0px',
                            width: '100%',
                            color: 'gold',
                            opacity: 1,
                            fontSize: `${(adjFontSize)}px`,
                            ...cooldownTextSx
                        }}>{cd > 60 ? `${Math.floor(cd / 60)}:${(cd % 60).toString().padStart(2, '0')}` : cd > 0 ? cd : ''}</Box>))}


                    </>)}
                </Button>
                {Math.max(...currentCooldown) > 0 && (
                    <>
                        <Button
                            className="clear-button"
                            sx={{
                                position: 'absolute',
                                top: '0px',
                                right: '0px',
                                opacity: 1,
                                // fontSize: '32px',
                                width: '100%',
                                height: '50%',
                                padding: 0,
                                lineHeight: 1,
                                color: 'red',
                                textAlign: 'center',
                                backgroundColor: 'rgba(0,0,0,.75)',
                                display: 'none',
                                '&:hover': {
                                    backgroundColor: 'rgba(0,0,0,1)',
                                }

                            }}
                            onClick={(e) => { handleResetClick() }}
                        >
                            Clear
                        </Button>
                        <Button
                            className="clear-button"
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                right: '0px',
                                opacity: 1,
                                // fontSize: '32px',
                                width: '100%',
                                height: '50%',
                                padding: 0,
                                lineHeight: 1,
                                color: 'red',
                                textAlign: 'center',
                                backgroundColor: 'rgba(0,0,0,.75)',
                                display: 'none',
                                '&:hover': {
                                    backgroundColor: 'rgba(0,0,0,1)',
                                }

                            }}
                            onClick={handleClick}
                        >
                            Restart
                        </Button>
                    </>)}

            </Box>
            {showLevels && (
                <Box sx={{ width: `${width}px`, lineHeight: 1 }}>
                    {spell.maxCooldown.map((cd, idx) => (
                        <Box key={idx}
                            display="inline-block"
                            sx={{
                                width: `${100 / spell.maxCooldown.length}%`,
                                backgroundColor: level >= idx ? 'gold' : 'white',
                                border: '1px 0px 0px 0px solid black',
                                borderLeft: idx === 0 ? '0px solid black' : '1px solid black',
                                cursor: 'pointer',
                                '&:hover': level !== idx ? {
                                    backgroundColor: '#d3bd44'
                                } : {},
                                textAlign: 'center',
                                boxSizing: 'border-box',
                                fontSize: '12px',
                                height: '24px',
                                lineHeight: '24px',

                                overflowX: 'hidden'
                            }}
                            onClick={() => { handleChangeLevel(idx) }}
                        >{adjCooldown(cd, isOctarineActive)}</Box>
                    ))}
                </Box>)}
        </Box>
    </Box >)
}