import { FC, useCallback } from "react"
import { Hero, Item } from "../../data/allHeroData"
import { Box, Button, SxProps } from "@mui/material";
import { useCurrentMatch } from "../../Providers/CurrentMatchProvider";
export type ToggleButtonProps = {
    item: Item;
    hero: Hero;
    height: number;
    width: number;
    sx?: SxProps;
    clickable?: boolean;
}

export const ToggleButton: FC<ToggleButtonProps> = ({ hero, item, height, width, sx, clickable }) => {
    const id = `${hero.name}_${item.name}`;
    const { match, updateToggle } = useCurrentMatch();
    const active = !!match.toggle[id]?.active;

    const handleClick = useCallback(() => {
        // cooldownRef.current = spellCooldown;
        // setCurrentCooldown(Math.round(spellCooldown / 1000));
        updateToggle({ id: id, active: !active });
    }, [id, updateToggle, active])


    return (<Box sx={{ border: '2px solid black', width: `${width}px`, boxSizing: 'content-box', height: `${height}px`, ...sx }}>
        <Box>
            <Box sx={{
                height: `${height}px`, width: `${width}px`, position: 'relative',
                '&:hover .clear-button': {
                    display: 'block'
                }
            }}>
                <Button sx={{ padding: 0 }} onClick={(clickable === undefined || clickable === true) ? handleClick : undefined}>
                    <img src={item.icon} alt={item.name} height={height} width={width} style={{ filter: `grayscale(${active ? 0 : 1})` }} />
                </Button>
            </Box>
        </Box>
    </Box >)
}