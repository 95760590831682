import { Button, ClickAwayListener, Paper, } from "@mui/material"
import { FC, useState } from "react";
import { HeroPickerHeroGrid } from "./HeroPickerHeroGrid";

export type HeroPickerProps = {
    onSelect: (name: string) => void;
    selectedHeroes: string[];
    onClear: () => void;
}

export const HeroPicker: FC<HeroPickerProps> = ({ onSelect: handleSelect, selectedHeroes, onClear: handleClear }) => {

    const [isOpen, setIsOpen] = useState<boolean>(false);
    // const isOpen = !!anchorEl;
    return <>
        <Button onClick={(e) => {
            setIsOpen(true);
        }}>
            Select Heroes
        </Button>

        {isOpen && (
            <ClickAwayListener onClickAway={() => {
                setIsOpen(false)
            }}>
                <Paper sx={{ display: isOpen ? 'block' : 'none', width: 'calc(100% - 32px)', marginLeft: '16px', position: 'absolute', zIndex: 9999 }} >
                    <HeroPickerHeroGrid onSelect={handleSelect} selectedHeroes={selectedHeroes} onClear={handleClear} />
                </Paper>
            </ClickAwayListener>)
        }
    </>
}