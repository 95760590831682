import { FC, useState } from "react";
import { HeroPicker } from "../Components/HeroPicker/HeroPicker";
import { HeroSpellBoardList } from "../Components/HeroSpellBoardList/HeroSpellBoardList";
import { useCurrentMatch } from "../Providers/CurrentMatchProvider";
import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import { TormentorButton } from "../Components/CooldownButton/TormentorButton";
import { GlyphButton } from "../Components/CooldownButton/GlyphButton";
import { RoshanButton } from "../Components/CooldownButton/RoshanButton";
import { AegisButton } from "../Components/CooldownButton/AegisButton";

export type MatchProps = {
    matchId?: number;
}
export const Match: FC<MatchProps> = ({ matchId }) => {
    // const [selectedHeroes, setSelectedHeroes] = useState<string[]>([]);
    const { updateSelectedHeroes, match } = useCurrentMatch();
    const [onlyShowLong, setOnlyShowLong] = useState<boolean>(true);
    const selectedHeroes = match.selectedHeroes ?? [];

    const handleSelectHero = (name: string) => {
        if (selectedHeroes.includes(name)) {
            updateSelectedHeroes(selectedHeroes.filter(h => h !== name));
        }
        else {
            updateSelectedHeroes([...selectedHeroes, name]);
        }
    }

    const handleClearHero = () => {
        updateSelectedHeroes([])
        // setSelectedHeroes([]);
    }
    return (<>
        <HeroPicker onSelect={handleSelectHero} selectedHeroes={selectedHeroes} onClear={handleClearHero} />
        <FormControlLabel sx={{ marginLeft: '24px' }} control={<Checkbox onChange={() => setOnlyShowLong(!onlyShowLong)} checked={onlyShowLong} />} label="Show only long cooldown spells" />
        <Stack direction="row" spacing="2px">
            <GlyphButton />
            <TormentorButton />
            <TormentorButton enemy />
            <AegisButton />
            <RoshanButton />
        </Stack>

        <HeroSpellBoardList onlyShowLong={onlyShowLong} selectedHeroes={selectedHeroes} />

    </>);
}