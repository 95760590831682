import { Box } from "@mui/material";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { Match } from "./Match";
import { CurrentMatchProvider } from "../Providers/CurrentMatchProvider";

export type HomeProps = {

}
export const Home: FC<HomeProps> = () => {
    const { matchId } = useParams<{ matchId?: string }>();
    // const [isLoading, setIsLoading] = useState<boolean>(false);

    return (<Box>
        <CurrentMatchProvider matchId={parseInt(matchId ?? '-1')}>
            <Match matchId={matchId ? parseInt(matchId) : undefined} />
        </CurrentMatchProvider>
    </Box>)
}