import { FC } from "react"
import { HeroSpellBoard } from "../HeroSpellBoard/HeroSpellBoard";
import { AllHeroData } from "../../data/allHeroData";
import { Box } from "@mui/material";

const ALL_HEROES = AllHeroData;
export type HeroSpellBoardListProps = {
    selectedHeroes: string[];
    onlyShowLong?: boolean;
}
export const HeroSpellBoardList: FC<HeroSpellBoardListProps> = ({ selectedHeroes, onlyShowLong }) => {
    return (<Box display="flex" sx={{
        padding: '8px',
        flex: "1 auto",
        flexWrap: "wrap"
    }}>
        {selectedHeroes.map((selected) =>
            <Box key={selected} sx={{ margin: "4px" }}>
                <HeroSpellBoard onlyShowLong={onlyShowLong} hero={ALL_HEROES.find(h => h.name === selected)!} />
            </Box>)
        }
    </Box>)
}