import { FC, PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { WebPubSubClient } from "@azure/web-pubsub-client";
import { v4 } from 'uuid';
import { SpellGroupMessage } from "./CurrentMatchProvider";


export type SocketContext = {
    webSocket: WebPubSubClient | null;
    userId: string;
    sendMatchData: (matchId: number, data: Partial<SpellGroupMessage>) => void;
}

export const socketContext = createContext<SocketContext>({
    webSocket: null,
    userId: '',
    sendMatchData: () => { }
});


export const useWebSocket = () => useContext(socketContext);

export type SocketProviderProps = {
} & PropsWithChildren;

export const SocketProvider: FC<SocketProviderProps> = ({ children }) => {
    const userIdRef = useRef(v4());
    const [webSocket] = useState<WebPubSubClient>(new WebPubSubClient({
        getClientAccessUrl: async () => {
            let value = await (await fetch(`/connect?userId=${userIdRef.current}`)).json();
            const url = value.url;
            return url;
        }
    }));
    const isStartedRef = useRef(false)

    useEffect(() => {
        if (!isStartedRef.current) {
            (async () => {
                webSocket.on('connected', async (e) => {
                    console.log('connected', e)
                });


                await webSocket.start();
            })();
            isStartedRef.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sendMatchData = useCallback((matchId: number, data: Partial<SpellGroupMessage>) => {
        if (webSocket && matchId && matchId > 0 && (data?.spell || data?.selectedHeroes || data?.toggle)) {
            webSocket.sendToGroup(matchId.toString(), {
                matchId,
                spell: data.spell,
                selectedHeroes: data.selectedHeroes,
                toggle: data.toggle,
                eventType: 'setState'
            } as SpellGroupMessage, 'json');
        }
    }, [webSocket]);

    const value = useMemo(() =>
        ({ webSocket, sendMatchData, userId: userIdRef.current }),
        [webSocket, sendMatchData]);
    return <socketContext.Provider value={value}>{children}</socketContext.Provider>
}