import { FC, PropsWithChildren, createContext, useCallback, useContext, useMemo, useRef, useState } from "react"

export type MatchSpell = {
    id: string;
    activatedTimestamp: number;
    level: number;
}

export type MatchToggle = {
    id: string;
    active: boolean;
}

export type MatchData = {
    matchId: number;
    data: Record<string, MatchSpell>;
    toggle: Record<string, MatchToggle>;
    selectedHeroes: string[];
}

export type GlobalData = {
    matches: Record<number, MatchData>;
    updateMatchData: (matchId: number, matchSpell: MatchSpell | undefined, selectedHeroes: string[] | undefined, toggle: MatchToggle | undefined) => void;
    getMatch: (matchId: number) => MatchData | undefined;
}

export const globalDataContext = createContext<GlobalData>({
    matches: {},
    updateMatchData: () => { },
    getMatch: () => undefined
});

export const useGlobalData = () => useContext(globalDataContext);

export type GlobalDataProviderProps = {
} & PropsWithChildren;

// let allMatches: Record<number, MatchData> = {};

export const GlobalDataProvider: FC<GlobalDataProviderProps> = ({ children }) => {
    const [matches, setMatches] = useState<Record<number, MatchData>>({});
    const allMatchesRef = useRef<Record<number, MatchData>>({});

    const updateMatchData = useCallback((matchId: number, matchSpell: MatchSpell | undefined, selectedHeroes: string[] | undefined, toggle: MatchToggle | undefined) => {
        let match = allMatchesRef.current[matchId];
        if (!match) {
            match = { matchId: matchId, data: {}, selectedHeroes: [], toggle: {} };
        }

        const newMatch: MatchData = {
            data: matchSpell === undefined ? match.data : {
                ...match.data,
                [matchSpell.id]: { ...matchSpell },
            },
            matchId: matchId,
            selectedHeroes: selectedHeroes === undefined ? match.selectedHeroes : selectedHeroes,
            toggle: toggle === undefined ? match.toggle : { ...match.toggle, [toggle.id]: { ...toggle } }
        };
        allMatchesRef.current = ({ ...allMatchesRef.current, [matchId]: newMatch });
        setMatches(allMatchesRef.current);
    }, [setMatches]);

    const getMatch = useCallback((matchId: number) => {
        return allMatchesRef.current[matchId];
    }, []);

    const value = useMemo(() => {
        return { matches, updateMatchData, getMatch }
    }, [matches, updateMatchData, getMatch])

    return <globalDataContext.Provider value={value}>{children}</globalDataContext.Provider>
}