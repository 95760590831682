import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material"
import MenuIcon from '@mui/icons-material/Menu';
import { SearchInput } from "./SearchInput";
import { useNavigate } from "react-router-dom";



export const Header = () => {
    const navigate = useNavigate();
    return <><Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
            <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Coach Dotato
                </Typography>

                <SearchInput
                    type="number"
                    placeholder="Join Match"
                    inputProps={{ 'aria-label': 'join match' }}
                    onKeyDown={(e) => {
                        if (e.key === '.' || e.key === 'e' || e.key === 'E' || e.key === '-') {
                            e.preventDefault();
                        }
                    }}
                    mode='ON_SUBMIT'
                    onSearch={(text) => navigate(`/${text}`)} />
            </Toolbar>
        </AppBar>
    </Box>
    </>
}