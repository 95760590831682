import { FC } from "react";
import { CooldownButton, CooldownButtonProps } from "./CooldownButton"

export type RoshanButtonProps = {

} & Omit<CooldownButtonProps, 'width' | 'height' | 'spell' | 'hero'>;

export const RoshanButton: FC<RoshanButtonProps> = ({ ...rest }) => {
    return (<CooldownButton
        hero={{ icon: '', name: 'roshan', spells: [] }}
        spell={{
            icon: './roshan.png',
            name: 'roshan',
            maxCooldown: [[8 * 60, 11 * 60]]
        }}
        width={128} height={128} {...rest} />);
}